import React, { Fragment } from "react"
import FilterProperties from "../components/properties/FilterProperties"
import FeaturedProperties from "../components/properties/FeaturedProperties"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BannerTop from "../components/bannerTop"
import ContactForm from "../components/forms/contact"

const Consulta = props => {
  return (
    <Layout>
      <SEO title="Nuestro equipo" />
      <BannerTop title="Agentes Inmobiliarios" />

      <div className="container contents lisitng-grid-layout">
        <div className="row">
          <div className="span9 main-wrap">
            <div className="main">
              <section className="listing-layout">
                <div className="inner-wrapper" style={{ borderBottom: "none" }}>
                  <ContactForm location={props.location} />
                </div>
              </section>
            </div>
          </div>
          <div className="span3 sidebar-wrap">
            <aside className="sidebar">
              <FeaturedProperties />
              <section className="widget advance-search">
                <h4 className="title search-heading">
                  Buscar Propiedad<i className="icon-search"></i>
                </h4>
                <div className="as-form-wrap">
                  <FilterProperties />
                </div>
              </section>
            </aside>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Consulta
